import Content, { ContentProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/content';
import Footer, { FooterProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/footer';
import Header, { HeaderProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/header';
import SidebarRoot, {
  SidebarProps,
} from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/sidebar';

const Sidebar = {
  Root: SidebarRoot,
  Content,
  Header,
  Footer,
};

export type { ContentProps, HeaderProps, SidebarProps, FooterProps };
export { Sidebar };
export default Sidebar;
