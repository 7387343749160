import cx from 'clsx';

import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import { ComponentHasChildren, ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ContentProps = ComponentHasStyling &
  ComponentHasChildren & {
    scrollable?: boolean;
    paddings?: boolean;
  };

const Content = ({ style, className, scrollable = true, paddings = true, children }: ContentProps) => {
  return (
    <div
      style={style}
      className={cx(
        styles.content,
        scrollable && styles.content_scrollable,
        !paddings && styles.content_noPaddings,
        className,
      )}
    >
      {scrollable ? (
        <Scrollable className={styles.scrollable}>
          <div className={styles.contentWrapper}>{children}</div>
        </Scrollable>
      ) : (
        children
      )}
    </div>
  );
};

export type { ContentProps };
export { Content };
export default Content;
