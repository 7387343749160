import Button from '@swe/shared/ui-kit/components/button';
import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import { Sidebar } from '@swe/shared/ui-kit/components/modal/desktop/common';

import { useNearestScrollable, ScrollPosition } from '@swe/shared/ui-kit/components/scrollable';
import Stack from '@swe/shared/ui-kit/components/stack';
import { capitalize } from '@swe/shared/utils/string';

import { useCallback, useEffect, useMemo, useRef } from 'react';

import styles from './styles.module.scss';

import StoreAbout from 'common/components/store-about';
import StoreList, { StoreListProps } from 'common/components/store-list';
import { DeliveryRoot } from 'common/containers/header/containers/sub-header/components/delivery-root';
import PickupRoot from 'common/containers/header/containers/sub-header/components/pickup-root';
import { getDeliveryOptions } from 'common/containers/header/containers/sub-header/constants';
import SearchAddress from 'common/containers/header/containers/sub-header/containers/search-address';
import { View } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery';
import { ShopFulfillmentType, ShopInfo, StoreDeliveryZone } from 'entities/shop/info';

type PickupStoreViewProps = {
  view: View.PickupStoreList | View.StoreDetails | View.DeliveryStoreList;
  selectedStoreId?: EntityID;
  previewStore?: ShopInfo;
  stores: ShopInfo[];
  onStorePick: (id?: EntityID) => void;
  onStorePreviewSelected: (id: EntityID) => void;
};

const PickupStoreView = ({
  view,
  selectedStoreId,
  previewStore,
  stores,
  onStorePreviewSelected,
  onStorePick,
}: PickupStoreViewProps) => {
  const scrollableContext = useNearestScrollable();
  const savedScrollPositionRef = useRef(0);

  /** WHY:
   * Because scroll control is owned by modal sidebar component, we have to save last scroll position
   * of the shop list, while we want to switch components between each other. Due to the fact they have different
   * heights, scrollPosition will be different for them (List scroll position should be stored and Details scroll
   * position should be always  0)
   *
   * TODO: Remove this code and make 2 methods inside ScrollableContext: savePosition, restorePosition;
   */
  useEffect(() => {
    if (view === View.PickupStoreList) {
      const handler = ({ scrollTop }: ScrollPosition) => {
        savedScrollPositionRef.current = scrollTop;
      };

      scrollableContext?.subscribe('scrollPosition', handler);

      return () => {
        scrollableContext?.unsubscribe('scrollPosition', handler);
      };
    }
  }, [view, scrollableContext]);

  useEffect(() => {
    if (view === View.PickupStoreList) {
      scrollableContext?.scroll({ top: savedScrollPositionRef.current, smooth: false });
    } else {
      scrollableContext?.scroll({ top: 0, smooth: false });
    }
  }, [scrollableContext, view]);

  return (
    <>
      <StoreList
        style={view === View.StoreDetails ? { display: 'none' } : undefined}
        className={styles.storeList}
        current={selectedStoreId}
        list={stores}
        onPick={onStorePick}
        onInfoClick={onStorePreviewSelected}
      />
      {view === View.StoreDetails && previewStore && (
        <StoreAbout
          {...previewStore}
          denseSchedule
          hideMap
        />
      )}
    </>
  );
};

type OrderSettingsSidebarProps = {
  orderType: ShopFulfillmentType;
  view: View;

  selectedStoreId?: EntityID;
  deliveryStore?: ShopInfo;
  deliveryZone?: StoreDeliveryZone;
  deliveryAddress?: string;
  deliveryAddressSecondLine?: string;
  isDeliveryLoading?: boolean;
  currentStore?: ShopInfo;
  previewStore?: ShopInfo;
  stores: StoreListProps['list'];
  nearestStores?: StoreListProps['list'];
  availableFulfillment: ShopFulfillmentType[];

  onDeliveryAddressSelected: (address: string) => void;
  onOrderTypeChange?: (o: ShopFulfillmentType) => void;
  onStoreSelected: (id?: EntityID) => void;
  onShowStoreList: (view: View.PickupStoreList | View.DeliveryStoreList) => void;
  onCloseStoreList: () => void;
  onShowStoreDetails: (id?: EntityID) => void;
  onCloseStoreDetails: () => void;
  onShowAddressSearch: () => void;
  onCloseAddressSearch: () => void;
  onAddressSecondLineChange: (address: string) => void;
  onSubmit: () => void;
  isStorePickupAvailable: boolean;
};

const OrderSettingsSidebar = ({
  orderType,
  view,
  deliveryStore,
  deliveryZone,
  deliveryAddress,
  deliveryAddressSecondLine,
  selectedStoreId,
  currentStore,
  stores,
  nearestStores,
  isDeliveryLoading,
  availableFulfillment,
  previewStore,
  onDeliveryAddressSelected,
  onOrderTypeChange,
  onShowStoreList,
  onCloseStoreList,
  onShowStoreDetails,
  onCloseStoreDetails,
  onShowAddressSearch,
  onCloseAddressSearch,
  onAddressSecondLineChange,
  onStoreSelected,
  onSubmit,
  isStorePickupAvailable,
}: OrderSettingsSidebarProps) => {
  const handleAddressSelected = useCallback(
    (address: string) => {
      onDeliveryAddressSelected(address);
    },
    [onDeliveryAddressSelected],
  );

  const Selector = useMemo(
    () => (
      <RadioGroup<ShopFulfillmentType>
        variant="section"
        name="delivery-type"
        value={orderType}
        options={getDeliveryOptions(availableFulfillment)}
        onChange={onOrderTypeChange}
      />
    ),
    [availableFulfillment, onOrderTypeChange, orderType],
  );

  switch (view) {
    case View.OrderType:
      return (
        <Sidebar.Root className={styles.root}>
          <Sidebar.Header>
            {availableFulfillment.length > 1 && 'Order Type'}
            {availableFulfillment.length === 1 && capitalize(availableFulfillment[0])}
          </Sidebar.Header>
          <Sidebar.Content>
            <Stack spacing="md">
              {view === View.OrderType && availableFulfillment.length > 1 && Selector}
              {orderType === ShopFulfillmentType.Delivery && (
                <DeliveryRoot
                  deliveryStore={deliveryStore}
                  deliveryZone={deliveryZone}
                  deliveryAddress={deliveryAddress}
                  deliveryAddressSecondLine={deliveryAddressSecondLine}
                  isLoading={isDeliveryLoading}
                  onOpenSearch={onShowAddressSearch}
                  onAddressSecondLineChange={onAddressSecondLineChange}
                  onStorePreviewSelected={onShowStoreDetails}
                  onViewAllStores={onShowStoreList}
                  storesCount={stores?.length}
                />
              )}
              {orderType === ShopFulfillmentType.Pickup && (
                <PickupRoot
                  currentStore={currentStore}
                  storesCount={stores?.length}
                  nearestStores={nearestStores}
                  onViewAllStores={onShowStoreList}
                  onStorePick={onStoreSelected}
                  onStorePreviewSelected={onShowStoreDetails}
                  isStorePickupAvailable={isStorePickupAvailable}
                />
              )}
            </Stack>
          </Sidebar.Content>
          <Sidebar.Footer>
            <Button
              block
              disabled={
                orderType === ShopFulfillmentType.Delivery
                  ? !(deliveryAddress && deliveryStore)
                  : !isStorePickupAvailable
              }
              onClick={onSubmit}
              name="shop-now"
            >
              Shop Now
            </Button>
          </Sidebar.Footer>
        </Sidebar.Root>
      );
    case View.DeliveryAddressInput:
      return (
        <Sidebar.Root className={styles.root}>
          <Sidebar.Header onClose={onCloseAddressSearch}>Enter Address</Sidebar.Header>
          <Sidebar.Content>
            <SearchAddress
              currentAddress={deliveryAddress}
              onAddressSelected={handleAddressSelected}
            />
          </Sidebar.Content>
        </Sidebar.Root>
      );
    case View.PickupStoreList:
    case View.DeliveryStoreList:
    case View.StoreDetails:
      return (
        <Sidebar.Root className={styles.root}>
          <>
            {view !== View.StoreDetails && <Sidebar.Header onClose={onCloseStoreList}>All Stores</Sidebar.Header>}
            {view === View.StoreDetails && (
              <Sidebar.Header onClose={onCloseStoreDetails}>{previewStore!.name}</Sidebar.Header>
            )}
          </>
          <Sidebar.Content paddings={view === View.StoreDetails}>
            <PickupStoreView
              view={view}
              stores={stores}
              previewStore={previewStore}
              selectedStoreId={selectedStoreId}
              onStorePick={onStoreSelected}
              onStorePreviewSelected={onShowStoreDetails}
            />
          </Sidebar.Content>
          {view === View.StoreDetails ? (
            <Sidebar.Footer>
              <Button
                block
                onClick={onSubmit}
                name="shop-now"
                disabled={!isStorePickupAvailable}
              >
                Shop Now
              </Button>
            </Sidebar.Footer>
          ) : null}
        </Sidebar.Root>
      );
    default:
      return null;
  }
};

export type { OrderSettingsSidebarProps };
export { OrderSettingsSidebar };
export default OrderSettingsSidebar;
