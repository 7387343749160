import cx from 'clsx';

import { ComponentHasChildren, ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type FooterProps = ComponentHasChildren & ComponentHasStyling;

const Footer = ({ style, className, children }: FooterProps) => {
  return (
    <div
      style={style}
      className={cx(className, styles.footer)}
    >
      {children}
    </div>
  );
};

export type { FooterProps };
export { Footer };
export default Footer;
