import Modal from '@swe/shared/ui-kit/components/modal';

import { OrderSettingsSidebar } from 'common/containers/header/containers/sub-header/components/order-settings-sidebar';
import Map from 'common/containers/header/containers/sub-header/containers/map';
import { usePickupDelivery } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery';
import { ShopFulfillmentType } from 'entities/shop/info';

const DesktopModals = () => {
  const {
    isOpened,
    close,
    orderType,
    pickup,
    delivery,
    mapProps,
    setDeliveryAddress,
    setStore,
    setOrderType,
    submit,
    view,
    availableFulfillment,
    showStorePreview,
    showStoreList,
    showAddressSearch,
    goPreviousView,
    goInitialView,
    isStorePickupAvailable,
    submittedAddress,
  } = usePickupDelivery();

  return (
    <Modal
      visible={isOpened}
      ariaLabel="Pickup/Delivery"
      sidebar={
        <OrderSettingsSidebar
          view={view}
          stores={orderType === ShopFulfillmentType.Delivery ? delivery.availableStores : pickup.stores}
          nearestStores={pickup.nearestStores}
          orderType={orderType}
          deliveryStore={delivery.deliveryStore}
          deliveryZone={delivery.deliveryZone}
          deliveryAddress={delivery.deliveryAddress}
          deliveryAddressSecondLine={delivery.deliveryAddressSecondLine}
          selectedStoreId={pickup.selectedStoreId}
          availableFulfillment={availableFulfillment}
          previewStore={pickup.selectedStore}
          currentStore={pickup.selectedStore ?? pickup.currentStore}
          isDeliveryLoading={delivery.isDeliveryLoading}
          onDeliveryAddressSelected={setDeliveryAddress}
          onStoreSelected={setStore}
          onShowStoreList={showStoreList}
          onShowStoreDetails={showStorePreview}
          onShowAddressSearch={showAddressSearch}
          onOrderTypeChange={setOrderType}
          onCloseAddressSearch={goInitialView}
          onCloseStoreDetails={goPreviousView}
          onCloseStoreList={goInitialView}
          onAddressSecondLineChange={delivery.setDeliveryAddressSecondLine}
          onSubmit={submit}
          isStorePickupAvailable={isStorePickupAvailable}
        />
      }
      background={<Map {...mapProps} />}
      onClose={submittedAddress ? close : undefined}
    />
  );
};

export { DesktopModals };
export default DesktopModals;
