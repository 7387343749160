import cx from 'clsx';
import { ReactElement } from 'react';

import { ContentProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/content';
import { FooterProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/footer';
import { HeaderProps } from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar/header';

import { ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type SidebarProps = ComponentHasStyling & {
  children?:
    | null
    | ReactElement<HeaderProps>
    | ReactElement<ContentProps>
    | ReactElement<FooterProps>
    | [ReactElement<HeaderProps> | null, ReactElement<ContentProps> | null]
    | [ReactElement<HeaderProps> | null, ReactElement<ContentProps> | null, ReactElement<FooterProps> | null];
};

const Sidebar = ({ style, className, children }: SidebarProps) => {
  return (
    <div
      style={style}
      className={cx(className, styles.root)}
    >
      {children}
    </div>
  );
};

export type { SidebarProps };
export { Sidebar };
export default Sidebar;
